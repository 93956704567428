exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nama-tsx": () => import("./../../../src/pages/o-nama.tsx" /* webpackChunkName: "component---src-pages-o-nama-tsx" */),
  "component---src-pages-prijavite-se-tsx": () => import("./../../../src/pages/prijavite-se.tsx" /* webpackChunkName: "component---src-pages-prijavite-se-tsx" */),
  "component---src-pages-resursi-tsx": () => import("./../../../src/pages/resursi.tsx" /* webpackChunkName: "component---src-pages-resursi-tsx" */),
  "component---src-pages-vesti-tsx": () => import("./../../../src/pages/vesti.tsx" /* webpackChunkName: "component---src-pages-vesti-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

