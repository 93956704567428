// import { ApolloError, gql, useQuery } from '@apollo/client';
// import React, { ReactNode, createContext, useContext } from 'react';

// export interface User {
//   id: string;
//   databaseId: number;
//   firstName: string;
//   lastName: string;
//   email: string;
//   capabilities: string[];
// }

// interface AuthData {
//   loggedIn: boolean;
//   user?: User;
//   loading: boolean;
//   error?: ApolloError;
// }

// const DEFAULT_STATE: AuthData = {
//   loggedIn: false,
//   user: undefined,
//   loading: false,
//   error: undefined,
// };

// const AuthContext = createContext(DEFAULT_STATE);

// export const GET_USER = gql`
//   query getUser {
//     viewer {
//       id
//       databaseId
//       firstName
//       lastName
//       email
//       capabilities
//     }
//   }
// `;

// export function AuthProvider({ children }: { children: ReactNode }) {
//   const { data, loading, error } = useQuery(GET_USER);
//   const user = data?.viewer;
//   const loggedIn = Boolean(user);

//   const value = {
//     loggedIn,
//     user,
//     loading,
//     error,
//   };

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// }

// const useAuth = () => useContext(AuthContext);

// export default useAuth;

import { ApolloError, gql, useQuery } from '@apollo/client';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface User {
  id: string;
  databaseId: number;
  firstName: string;
  lastName: string;
  email: string;
  capabilities: string[];
}

interface AuthData {
  loggedIn: boolean;
  user?: User;
  loading: boolean;
  error?: ApolloError;
}

const DEFAULT_STATE: AuthData = {
  loggedIn: false,
  user: undefined,
  loading: true,
  error: undefined,
};

const AuthContext = createContext<AuthData>(DEFAULT_STATE);

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER);
  const [authState, setAuthState] = useState<AuthData>(DEFAULT_STATE);

  useEffect(() => {
    if (!loading) {
      setAuthState({
        loggedIn: Boolean(data?.viewer),
        user: data?.viewer,
        loading: false,
        error,
      });
    }
  }, [loading, data, error]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
