import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';

import fetch from 'cross-fetch';

const link = createHttpLink({
  fetch,
  uri: 'https://www.blog.afirmacijakulture.org/graphql',
  credentials: 'include',
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
